<template>
  <!-- 最外层div -->
  <div class="left-first">
    <!-- 标题div -->
    <div>
      <p><span class="verticalLine"></span><span>综合能源服务:</span></p>
    </div>
    <div class="center" @click="centerDialogVisible = true">
      <ul>
        <!--<li v-for="i in item" :key="i.value"><img src="../../assets/sign.svg" width="16">
          <span>{{i.lable}}</span>
        </li>-->
        <li>与企业共建光伏、储能等分布式能源；开展配电网业务，运营增量配电网，为用户提供安全、方便、快捷的供电服务；围绕企业能源供应，将公共热冷、电力、燃气、水通过能源管理平台监测耗能，达到节约减排目标。</li>
      </ul>
    </div>
    <!-- 弹窗-->
    <el-dialog :visible.sync="centerDialogVisible" width="1100px" top="15vh" center>
      <popup></popup>
    </el-dialog>
  </div>
  <!-- 最外层div over-->
</template>
<script>
import popup from '../echarts/Popup'
export default {
  components:{
    popup
  },
  props:{
  },
  data(){
    return{
      item:[
        {lable: '与企业共建光伏、储能等分布式能源。', value: '1'},
        {lable: '开展配电网业务，运营增量配电网，为用户提供安全、方便、快捷的供电服务。', value: '2'},
        {lable: '围绕企业能源供应，将公共热冷、电力、燃气、水通过能源管理平台监测耗能，达到节约减排目标。', value: '3'}
      ],
      centerDialogVisible: false
    }
  },
  mounted() {
  },
  methods:{
  }
}
</script>
<style lang="less" scoped>
.left-first{
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* 定高 */
  height: 19rem;
  background: rgba(25, 38, 58, 0.8);
  margin-top: 20px;
  overflow: hidden;
}
/* 标题 */
.left-first>div:first-child{
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* border: 1px solid rgb(243, 245, 242); */
  /* 定高 */
  height: 2.125rem;
  color: #fff;
  font-size: .875rem;
  display: flex;
  line-height: 2.125rem;
  justify-content: space-between;
  padding:2rem 1.1rem 0rem ;
  background-color: rgba(25, 38, 58, 0.8);
}
.left-first>div:first-child>p:nth-child(1){
  color: #04EBF9;
  position: relative;
  span:nth-of-type(2){
    margin-left: 10px;
  }
}
.left-first>div:first-child>p:nth-child(2){
  color: #ACD8E1;
}
.left-first-left{
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  flex-basis: 46.5%;
  color: #3F6979;
  font-size: 12px;
  /*text-align: left;*/
}
.left-first-left div:nth-of-type(1){
  text-align: left;
  padding: 10px 0 0 20px;
  p{
    line-height: 30px;
  }
}
.left-first-left div:nth-of-type(2){
  text-align: left;
  padding: 10px 0 0 20px;
  li{list-style: none}
  li:nth-of-type(2){margin-top: 10px}
  p{
    line-height: 26px;
  }
}
.left-first-right{
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  flex-basis: 53.5%;
}
/* 内容内部 */
.left-first-left>div{
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /*定宽 */
  width: 13.5rem;
}

.left-first-left>div:first-child{
  background-image: url("../../static/image/left/月度1.png");
  background-repeat: no-repeat;
  /* 定高 */
  height: 6.5rem;
  margin-top: .5rem;
}
.left-first-left>div:nth-child(2){
  background-image: url("../../static/image/left/月度2.png");
  background-repeat: no-repeat;
  /* 定高 */
  height: 8.7rem;
}
.center{
  height: 100%;
}
.center ul{
  padding-top: 60px;
}
.center li{
  list-style: none;
  text-align: left;
  color: #ACD8E1;
  font-size: 16px;
  line-height: 36px;
  margin: 0 50px;
  cursor: pointer;
  display: flex;
}
.center li img{
  margin-right: 20px;
  vertical-align: middle;
  height: 36px;
}
.verticalLine{
  position: absolute;
  display: inline-block;
  border-right: 2px solid;
  height: 16px;
  top: 10px;
  color: #04EBF9;
}
/deep/ .el-dialog{
  border-radius: 10px;
}
/deep/ .el-dialog__header{
  display: none;
}
/deep/ .el-dialog__body{
  min-height: 300px;
  padding: 0 !important;
  color: #333;
}
/deep/ .el-dialog__wrapper{
  overflow: hidden;
}
.popup{
  position: relative;
}
.popup>div:nth-of-type(1){
      padding: 25px 40px 20px;
}
.popup p{
  line-height: 40px;
  font-size: 16px;
  //font-weight: 600;
  font-family: '微软雅黑';
}
.popup .verticalLine{
  top: 56px;
  height: 20px;
  color: #2BA990;
}
.popup p span:nth-of-type(2){
  margin-left: 20px;
  color: #666;
  font-size: 18px;
}
.popup div:nth-of-type(1)>p:nth-of-type(2){
  color: #333;
  font-weight: 800;
  font-size: 16px;
  margin-top: 30px;
  line-height: 30px;
  padding: 0 6px;
  border-bottom: 1px solid #ccc;
}
.popup p:nth-of-type(3){
  padding: 0 38px
}
.popup_conter{
  height: 640px;
  overflow-y: auto;
  padding: 0 50px;
}
.popup_conter li{
  list-style: none;
}
.popup_conter img{
  margin: 0 auto;
  display: block;
}
/* 设置滚动条的样式 */
.popup_conter::-webkit-scrollbar {
  width: 6px;
}
/* 滚动槽 */
.popup_conter::-webkit-scrollbar-track {
  -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
  border-radius:10px;
}
/* 滚动条滑块 */
.popup_conter::-webkit-scrollbar-thumb {
  border-radius:10px;
  background:rgba(48, 46, 53, 0.5);
  -webkit-box-shadow:inset 006px rgba(0,0,0,0.5);
}
.popup_conter::-webkit-scrollbar-thumb:window-inactive {
  background:rgba(39, 57, 92, 0.4);
}
.triangle{
  color: #0865c7;
}
.triangle span:nth-of-type(2){
  font-weight: 600;
  font-size: 20px !important;
  color: #0865c7 !important;
}
.triangle_p {
  color: #0865c7;
  margin-top: -10px;
  padding-left: 34px;
}
.triangle_div{
  display: flex;
  margin-top: 30px;
}
.triangle_li{
  padding:60px 10px 0 60px;
  background: #f6f3f3;
  margin-right: 20px;
}
</style>
