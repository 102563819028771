<template>
  <div class="dia-out">
    <el-dialog
      :visible.sync="dialogVisible"
      :show-close="false"
      width="1100px"
      @closed="closed"
      :modal-append-to-body="false">
      <!-- 标题 -->
      <p class="title">
        {{diaData.areasName}}售电详情
      </p>

      <!-- 两个卡片 -->
      <div class="cards">
        <!-- 遍历产生卡片 -->
        <div v-for="(obj,index) of cards" :key="index">
          <img :src="obj.img" alt="图裂">
          <!-- 详情 -->
          <div>
            <p>
              {{obj.text}}：
            </p>
            <p>
              {{diaData[obj.name]}}
            </p>
          </div>    <!-- 详情 over-->
        </div>      <!-- 遍历产生卡片 over-->
      </div>    <!-- 两个卡片 over-->

      <div>
        前五名客户详情：
      </div>
      
      <!-- 排名列表 -->
      <div class="list">
        <!-- 左侧 -->
        <div>
          <p v-for="(obj,index) of diaData.clients" :key="index">
            {{index+1}}. {{obj.clientName}}
          </p>
        </div>
        <!-- 左侧 over-->

        <!-- 右侧 -->
        <div>
          <p v-for="(obj,index) of diaData.clients" :key="index">
            <!-- 占位 使左侧内容区域与右侧同高 -->
            <span style="visibility:hidden">占</span>
            {{obj.contractElectricity}}kWh
          </p>
        </div>
        <!-- 右侧 over-->
      </div>      <!-- 排名列表 over-->
      
    </el-dialog>
  </div>
</template>

<script>
export default{
  props:{
    // 父组件中控制本组件显/隐变量
    isOpen:{
      type:Boolean,
      default:()=>{
        return false
      }
    },
    // 弹窗中所显示数据(父组件中获取对应后端数据 直接传入)
    diaData:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  watch:{
    isOpen:{
      // 如父组件中控制本弹窗组件显示的变量为true 则弹出弹窗
      handler(newValue){
        if(newValue){
          this.$nextTick(()=>{
            this.dialogVisible = newValue;
            // console.log(newValue);
          })
        }
      },
      immediate:true
    }
  },
  data(){
    return{
      // 弹窗显/隐
      dialogVisible:false,
      // 卡片内容显示容器
      cards:[
        {
          text:"客户数量(户)",
          // 从数据容器中取属性值的字段名
          name:"caTotal",
          img:require("../../static/image/center/map/dialog/dialogOne.svg")
        },
        {
          text:"签约电量(万kWh)",
          // 从数据容器中取属性值的字段名
          name:"eleTotal",
          img:require("../../static/image/center/map/dialog/dialogTwo.svg")
        }
      ]
    }
  },
  methods:{
    // 弹窗关闭动画结束时调用
    closed(){
      // 变更父组件中控制弹窗显/隐的变量
      this.$emit("diaClose");
    }
  }
}
</script>


<style scoped lang="less">
  div{
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  } 
  .title{
    font-size: 24px;
    color: #666666;
    text-align: left;
    padding-left: 1rem;
    // 定高
    height: 2.125rem;
    line-height: 2.125rem;
    // border: 1px solid rgb(5, 63, 250);
    border-left:5px solid #2BA990;
  }
  // 卡片部分
  .cards{
    // 定高
    height: 9.6875rem;
    display: flex;
    // border: 1px solid rgb(250, 10, 250);
  }
  .cards>div{
    flex-basis: 50%;
    display: flex;
    // border: 1px solid rgb(131, 240, 7);
    align-items: center;
  }
  .cards img{
    // 定宽定高
    width: 4.6375rem;
    height: 4.125rem;
    // border: 1px solid #ccc;
    margin-right: 1.625rem;
    margin-left: 7.8125rem;
  }
  .cards>div>div>p:first-child{
    font-size: 22px;
    // border: 1px solid #ccc;
  }
  .cards>div>div>p:nth-child(2){
    font-size: 36px;
    font-weight: 600;
    color: #333;
    margin-top: .5rem;
    text-align: left;
    // border: 1px solid rgb(84, 10, 255);
  }
  // 排名部分
  .cards+div{
    padding-top: 1.375rem;
    padding-bottom: 1.375rem;
    padding-left: 1.2rem;
    color: #333;
    font-size: 22px;
    text-align: left;
    // border: 1px solid rgb(231, 12, 12);
  }
  .list{
    // 定宽定高
    width: 59.375rem;
    height: 22.75rem;
    background-color: #FAFAFA;
    border-radius: 8px;
    // border: 1px solid rgb(240, 146, 5);
    padding-left: 4.375rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .list>div:first-child{
    min-width: 13.75rem;
    color: #666666;
    // border: 1px solid #ccc;
  }
  .list>div:nth-child(2){
    min-width: 19.875rem;
    font-size: 24px;
    // border: 1px solid rgb(252, 137, 6);
    color: #333;
    font-weight: 600;
  }
  // 每行
  .list>div>p{
    font-size: 24px;
    text-align: left;
    padding-top: 1.21875rem;
    padding-bottom: 1.21875rem;
    // border: 1px solid rgb(8, 105, 231);
  }
</style>