<template>
<!-- 最外层div -->
  <div class="right-third">
    <!-- 标题div -->
    <div>
      <p>各行业用户签约电量分析</p>
<!--      <p>{{item.time}}</p>-->
    </div>      <!-- 标题div over-->
    <!-- 内容div -->
    <div >
      <!-- 内容左侧 -->
      <div class="right-third-left">
        <RightBottomEcharts :item="item.industryList" :key="trem" v-if="item.industryList"  :item1="item1" :item2="item2"></RightBottomEcharts>

      </div>    <!-- 内容左侧 over-->

      <!-- 内容右侧 -->
      <div class="right-third-right">
         <!-- 右侧框 -->
        <div>
          <li v-for="i in item.industryList">
            <p style="width: 150px;white-space: nowrap;text-overflow:ellipsis; overflow:hidden;cursor: pointer" :title="i.name+'(万kWh)'"><span></span> {{i.name}}(万kWh)</p>
            <p style="color: #9CC4CC;font-size: 20px">{{i.total}}</p>
          </li>
        </div>    <!-- 右侧框 over-->
      </div>    <!-- 内容右侧 over-->

    </div>      <!-- 内容div over-->
  </div>
<!-- 最外层div over-->
</template>
<script>
import RightBottomEcharts from '../echarts/RightBottomEcharts'
import * as method from "@/api";
export default {
  components:{
    RightBottomEcharts
  },
  props:{
  },
  data(){
    return{
      item:{},
      trem:1,
      item1: [],
      item2: [],
    }
  },
  mounted() {
    this.number()
    let vm = this
    setInterval(function (){
      vm.trem++;
    },3000)
  },
  methods:{
    number() {
      method.getMethod('/sdyw/analysis/industry/number', {
      }).then(res => {
        /*this.item = res
        res.industryList.forEach((i, index) =>{
          this.item1.push({ 'name': i.percent + '%' , 'max': i.total+(parseFloat(i.total)* (index*0.1+1))})
          this.item2.push(i.total)
        })*/
        this.item = res
        let num = 0
        res.industryList.forEach((i, index) =>{
          num +=i.total
          // 'max': i.total*(i.percent)*0.1
          this.item1.push({ 'name': i.percent + '%' , 'max': ''})
          this.item2.push(i.total)
        })
        this.item1.forEach(v =>{
          v.max = num
        })
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .right-third{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* 定高 */
    height: 20.2rem;
  }
  /* 标题 */
  .right-third>div:first-child{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* border: 1px solid rgb(243, 245, 242); */
    /* 定高 */
    height: 2.125rem;
    color: #fff;
    font-size: .875rem;
    display: flex;
    line-height: 2.125rem;
    justify-content: space-between;
    padding:0rem 1.1rem 0rem ;
    border-left:3px solid #04EBF9;
    background-color: rgba(58, 64, 92, 0.6);
  }
  .right-third>div:first-child>p:nth-child(1){
    color: #04EBF9;
  }
  .right-third>div:first-child>p:nth-child(2){
    color: #ACD8E1;
  }
  /* 内容 */
  .right-third>div:nth-child(2){
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* 定高 */
    height: 16.5rem;
    margin-top: .8rem;
    display: flex;
  }
  .right-third-left{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    flex-basis: 53.5%;
  }
  .right-third-right{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    flex-basis: 46.5%;
    color: #738F9B;
    font-size: 12px;
  }
  .right-third-right div:nth-of-type(1){
    text-align: left;
    padding: 10px 0 0 20px;
    li{
      list-style: none;
      margin-top: 4px;
      p:nth-of-type(2){padding-left: 24px;}
    }
    p{
      line-height: 20px;
    }
    span{
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 12px;
    }
    li:nth-of-type(1) span{background: #135D52;}
    li:nth-of-type(2) span{background: #C1815D;}
    li:nth-of-type(3) span{background: #3F6979;}
    li:nth-of-type(4) span{background: #47327F;}
    li:nth-of-type(5) span{background: #9C8C03;}
  }
  /* 内容内部 */
  .right-third-right>div{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /*定宽 */
    width: 13.5rem;
  }

  .right-third-right>div:first-child{
    background-image: url("../../static/image/right/售电利润.png");
    background-repeat: no-repeat;
    /* 定高 */
    height: 15.8rem;
    margin-top: .5rem;
  }
</style>
