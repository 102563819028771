<template>
<!-- 最外层div -->
  <div class="left-first">
    <!-- 标题div -->
    <div>
      <p>战略合作企业</p>
<!--      <p>{{item.time}}</p>-->
    </div>      <!-- 标题div over-->
    <!-- 内容div -->
    <div >
      <el-table
          :data="tableData"
          stripe
          style="width: 100%">
        <el-table-column type="index" label="排名" ></el-table-column>
        <el-table-column prop="clientName" label="客户名称" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="contractElectricity" label="签约电量(万kWh)"></el-table-column>
<!--        <el-table-column prop="profit" label="收益(万元)" width="120"></el-table-column>-->
      </el-table>

    </div>      <!-- 内容div over-->
  </div>
<!-- 最外层div over-->
</template>
<script>
import * as method from "@/api";
export default {
  components:{
  },
  props:{
  },
  data(){
    return{
      tableData: []
    }
  },
  mounted() {
    this.price();
  },
  methods:{
    price() {
      method.getMethod('/sdyw/analysis/topFive', {
      }).then(res => {
        this.tableData = res
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .left-first{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* 定高 */
    height: 20.2rem;
  }
  /* 标题 */
  .left-first>div:first-child{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* border: 1px solid rgb(243, 245, 242); */
    /* 定高 */
    height: 2.125rem;
    color: #fff;
    font-size: .875rem;
    display: flex;
    line-height: 2.125rem;
    justify-content: space-between;
    padding:0rem 1.1rem 0rem ;
    background-color: rgba(58, 64, 92, 0.6);
  }
  .left-first>div:first-child>p:nth-child(1){
    color: #04EBF9;
  }
  .left-first>div:first-child>p:nth-child(2){
    color: #ACD8E1;
  }
  /* 内容 */
  .left-first>div:nth-child(2){
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* 定高 */
    height: 16.5rem;
    margin-top: 1.2rem;
    display: flex;
  }
  .left-first-left{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    flex-basis: 46.5%;
    color: #3F6979;
    font-size: 12px;
    /*text-align: left;*/
  }
  .left-first-left div:nth-of-type(1){
    text-align: left;
    padding: 10px 0 0 20px;
    p{
      line-height: 30px;
    }
  }
  .left-first-left div:nth-of-type(2){
    text-align: left;
    padding: 10px 0 0 20px;
    li{list-style: none}
    li:nth-of-type(2){margin-top: 10px}
    p{
      line-height: 26px;
    }
  }
  .left-first-right{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    flex-basis: 53.5%;
  }
  /* 内容内部 */
  .left-first-left>div{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /*定宽 */
    width: 13.5rem;
  }

  .left-first-left>div:first-child{
    background-image: url("../../static/image/left/月度1.png");
    background-repeat: no-repeat;
    /* 定高 */
    height: 6.5rem;
    margin-top: .5rem;
  }
  .left-first-left>div:nth-child(2){
    background-image: url("../../static/image/left/月度2.png");
    background-repeat: no-repeat;
    /* 定高 */
    height: 8.7rem;
  }
  /deep/.el-table th>.cell{
    font-weight: initial;
    color: #738F9B;
    text-align: center;
  }
  /deep/ .el-table, /deep/.el-table__expanded-cell{
    background: none;
  }
  /deep/.el-table td, /deep/.el-table th.is-leaf{
    border: none;
  }
  /deep/ .el-table th, /deep/.el-table tr{
    background: none;
  }
  /deep/.el-table td, .el-table th{
    padding: 9px 0;
  }
  /deep/.el-table--striped .el-table__body tr.el-table__row td{
    background: rgba(19, 91, 112, 0.6);
  }
  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td{
    background: none;
  }
  /deep/.el-table td, .el-table th.is-leaf{
    border: none;
  }
  /deep/.el-table .cell{
    color: #ACD8E1;
    text-align: center;
  }
  /deep/ .el-table::before{
    height: 0;
  }
.el-tooltip__popper{
    min-width: 100px !important;

  }
  /deep/ .el-table .el-table__row .el-table_1_column_1 .cell div {
    width: 20px;
    height: 20px;
    margin: 0 auto;
    border-radius: 50%;
    text-align: center;
    line-height: 20px;
  }
/* /deep/ .el-table .el-table__row:nth-of-type(1) .el-table_1_column_1 .cell div {
   background: #04CFDB;
}
  /deep/ .el-table .el-table__row:nth-of-type(2) .el-table_1_column_1 .cell div {
    background: #0398A1;
  }
  /deep/ .el-table .el-table__row:nth-of-type(3) .el-table_1_column_1 .cell div {
    background: #027178;
  }*/
  /deep/.el-table--striped .el-table__body tr.el-table__row .el-table_1_column_1{
    border-radius: 5px 0 0 5px;
  }
  /deep/.el-table--striped .el-table__body tr.el-table__row .el-table_1_column_4{
    border-radius: 0 5px 5px 0;
  }
</style>
