<template>
  <!-- 最外层div -->
  <div class="center-first">
    <!-- 签约电量 -->
    <div>
      <p>
        签约电量(万kWh)
      </p>
      <div class="number-1">
        <!-- 电子数字补零 -->
<!--        <div>-->
<!--          <Number :numAttr="numberZero"></Number>-->
<!--        </div>    &lt;!&ndash; 电子数字补零 over &ndash;&gt;-->
        <!-- 电子数值 -->
        <div>
<!--          <Number :numAttr="numberE"></Number>-->
          <count-to :startVal='0' :endVal='parseFloat(endVal)' :duration='3000' :key="trem" class="countNum"></count-to>
        </div>
        <!-- 电子数值 over -->
      </div>
    </div>    <!-- 签约电量 over-->
    <!-- 签约客户 -->
    <div>
      <p>
        签约客户(户)
      </p>
      <div class="number-2">
        <!-- 电子数值 -->
        <div >
<!--         <Number :numAttr="numberC"></Number>-->
          <count-to :startVal='0' :endVal='parseFloat(caCount)' :duration='3000' :key="trem" class="countNum"></count-to>
        </div>
        <!-- 电子数值 over -->
      </div>
    </div>    <!-- 签约客户 over-->
    <!-- 天气日期 -->
    <div>
      <!-- 天气图片 -->
      <div>
        <!--!! 动态图片 !!!-->
        <!-- 可往路径中拼接/从数组中取(看后端返回数据格式和图片名) -->
        <!-- 为防止没有对应图 尽量从数组中取 -->
        <img :src="picture" alt="sorry 图裂了">
        <!-- <img :src="weather[p]" alt="sorry 图裂了"> -->
      </div>  <!-- 天气图片 over-->
      <!-- 时间日期 -->
      <div class="time">
        <p>
          {{hour}}:{{minutes}}
        </p>
        <p>
          {{year}}年{{month}}月{{date}}日 周{{day}}
        </p>
      </div>    <!-- 时间日期 over-->
    </div>    <!-- 天气日期 over-->
  </div>  <!-- 最外层div over-->

</template>

<script>
// 引入数字字体子组件
import Number from "../font/Font.vue"
import countTo from 'vue-count-to';
// 引入发送请求组件
import * as method from "@/api";

export default {
  components: {
    // 配置为子组件
    Number,
    countTo
  },
  data(){
    return{
      // 第一部分数字
      numberE:{
        num:0,
        big:0.7,
        styleColer:"#fff"
      },
      numberZero:{
        num:"",
        big:0.7,
        styleColer:"rgba(156, 170, 165,.6)"
      },
      // 第二部分数字
      numberC:{
        num:0,
        big:0.7,
        styleColer:"#fff"
      },
      // 控制天气图片
      picture:"",
      // 当前时间
      year:"YYYY",
      month:"MM",
      date:"DD",
      day:"d",
      hour:"hh",
      minutes:"mm",
      endVal: 0,
      caCount: 0,
      trem:""
    }
  },
  mounted(){
    // 初始情况下获取时间
    this.get_year();
    this.get_mounth();
    this.get_date();
    this.get_day();
    this.get_hour();
    this.get_minutes();
    // 每分钟都获取分钟
    setInterval(()=>{
      this.get_minutes();
    },1000)
    setInterval(()=>{
      this.trem++
    },10000)
    // 获取客户数和签约电量
    this.get_C_E();
    // 获取天气
    this.get_weather();
  },
  methods:{
    // 获取年
    get_year(){
      this.year=new Date().getFullYear();
    },
    // 获取月
    get_mounth(){
      let MM=new Date().getMonth()+1;
      if(MM<10){
        this.month=`0${MM}`;
      }else{
        this.month=MM;
      }
    },
    // 获取日
    get_date(){
      let DD=new Date().getDate();
      if(DD<10){
        this.date=`0${DD}`;
      }else{
        this.date=DD;
      }
    },
    // 获取星期
    get_day(){
      let week=[
        "日","一","二","三","四","五","六"
      ]
      let dd=new Date().getDay();
      this.day=week[dd];
    },
    //获取小时
    get_hour(){
      let hh=new Date().getHours();
      // console.log("获取小时");
      if(hh<10){
        this.hour=`0${hh}`
      }else{
        this.hour=hh
      }
    },
    // 获取分钟
    get_minutes(){
      let mm=new Date().getMinutes();
      if(mm<10){
        this.minutes=`0${mm}`
        // 分钟数到达0时 获取小时数
        if(mm==0){
          this. get_hour();
        }
      }else{
        this.minutes=mm
      }
    },
    // 获取签约电量和签约客户
    get_C_E(){
       method.getMethod("/sdyw/dashboard/statistics",{
       }).then(res=>{
          // console.log(res);
          // console.log(typeof res.contractElectricity);
          // 签约电量滚动
          // 首先有一个初始值
          let n=(res.contractElectricity)*99.99/100;
          n=Math.floor(n);
          this.numberE.num=n;
          let Timer1=setInterval(()=>{
            if(this.numberE.num<res.contractElectricity){
              this.numberE.num +=1
            }else{
             window.clearInterval(Timer1);
            }
          },0.0001)
          // 签约客户滚动
          let c=(res.caCount)*99/100;
          c=Math.floor(c);
          this.numberC.num=c;
          /*let Timer2=setInterval(()=>{
            if(this.numberC.num<res.caCount){
              this.numberC.num +=1;
            }else{
             window.clearInterval(Timer2);
            }
          },.0001) ;*/
          // this.numberE.num=res.contractElectricity;
         res.contractElectricity ? this.endVal = res.contractElectricity : this.endVal = 0;
         res.caCount ? this.caCount = res.caCount : this.caCount = 0;
          // 准备补零
          let numberLength=res.contractElectricity.toString();
          numberLength=numberLength.length;
          let zero=8-numberLength;
          // 设置字符串用于截取
          let str="00000000"
          // 若数字位数小于8 则补零
          if(zero>0){
            this.numberZero.num = str.substr(`-${zero}`);
            this.numberZero.num = "0";
            // console.log(this.numberZero.num);
          }
       })
    },
    // 获取天气
    // 类型:xue、lei、shachen、wu、bingbao、yun、yu、yin、qing
    get_weather(){
      method.getMethod("/sdyw/weather",{
      }).then(res=>{
        this.picture=require(`../../static/image/center/weather/${res}.png`)
      })
    }
  }
}
</script>


<style scoped>
.center-first{
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* border: 1px solid rgb(205, 4, 245); */
  /* 定高定宽 */
  height: 6.5rem;
  width: 47rem;
  margin: 0 auto;
  background-image: url("../../static/image/center/bg.png");
  background-repeat: no-repeat;
  display:flex ;
  padding-top: 1.2rem;
  padding-left: 2rem;
}
.center-first>div{
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* border: 1px solid rgb(156, 170, 165); */
}
.center-first>div:first-child{
  flex-basis: 35%;
  color: #fff;
  font-size: .875rem;
  text-align: left;
}
.center-first>div:nth-child(2){
  flex-basis: 30%;
  color: #fff;
  font-size: .875rem;
  text-align: left;
}
.center-first>div:nth-child(3){
  flex-basis: 35%;
  display: flex;
  justify-content: space-between;
}
/* 左侧 */
.number-1{
  /* border: 1px solid #fff; */
  display: flex;
  margin-top: .6rem;
}
/* 中间 */
.number-2{
  margin-top: .6rem;
}
/* 右侧 */
/* 左 */
.center-first>div:nth-child(3)>div:first-child{
  /* 定宽 */
  flex-basis: 25%;
  /* border:1px solid #fff; */
  padding-top: .7rem;
}
/* 右 */
.center-first>div:nth-child(3)>div:nth-child(2){
  /* border:1px solid #fff; */
  /* 定宽 */
  flex-basis: 72%;
}
.time{
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding-top: 1rem;
  text-align: left;
  color: #ACD8E1;
}
.time>p:first-child{
  font-size: 1.3rem;
}
.time>p:nth-child(2){
  font-size: .875rem;
}
.countNum{
  font-size: 28px;
}
</style>
