<template>
<div class="box">
  <div class="box_top">
    <div>
      <p><span></span>>1500</p>
      <p>{{item.percents[4]+'%'}}</p>
      <p>{{item.numbers[4]}}</p>
    </div>
    <div>
      <p><span></span>1000-1500</p>
      <p>{{item.percents[3]+'%'}}</p>
      <p>{{item.numbers[3]}}</p>
    </div>
  </div>
  <div
    :id="echarts"
    class="echarts"
    ref="echarts"
  ></div>

  <div class="box_bottom">
    <div>
      <p><span></span><200</p>
      <p>{{item.percents[0]+'%'}}</p>
      <p>{{item.numbers[0]}}</p>
    </div>
    <div>
      <p><span></span>200-500</p>
      <p>{{item.percents[1]+'%'}}</p>
      <p>{{item.numbers[1]}}</p>
    </div>
    <div>
      <p><span></span>500-1000</p>
      <p>{{item.percents[1]+'%'}}</p>
      <p>{{item.numbers[1]}}</p>
    </div>
  </div>
</div>

</template>
<script>
import echarts from "echarts";
export default {
  props: {
    // 接收父组件传递过来的信息
    AData: {
      type: Array,
    },
    item: {
      type: Object,
    },
    total: {
      type: Number,
    },
  },
  data() {
    return {
      // total:0
    };
  },
  methods: {
    drawChart() {
      // let title = '总数';
      var myChart = this.$echarts.init(document.getElementById(this.echarts));
      myChart.setOption({
        tooltip:{
        enterable:true
        },
        series: [
          {
            // color:[ "#FFB156","#0E6F8C","#1E9281","#6748B8"],
            silent: true,
            name: "",
            type: "pie",
            avoidLabelOverlap: true,   //是否启用防止标签重叠策略
            radius: ["40%", "52%"],
            center: ["50%", "44%"],
            // avoidLabelOverlap: false,
            hoverAnimation: false,
            label: {
                padding: [0, 0, 0, 0],
                show: true,
                position: 'outside',
                formatter: [
                    // '{name|{b}}\n{text|{d}}{percent|%}\n{text|{c}}',//引导线上面文字
                    // '{yuandian|●},{name|{b}}\n{text|{d}}{percent|%}\n{text|{c}}',//引导线上面文字
                    // '{text|{c}}','{text|{d}}{percent|%}', //引导线下面文字
                    // '{text|{d}}{percent|%}', //引导线下面文字
                ].join("   "), //用\n来换行
                rich: {
                    name: {
                        fontSize: 12,
                        color:'#ccc',
                        padding: [4, -4],

                    },
                    text: {
                        fontSize: 12,
                        padding: [1, 0],
                        color:'#fff',
                    },
                    percent:{
                        fontSize: 12,
                        padding: [0, 0],
                        color:'#ccc'
                    },
                }
            },
            labelLine: {
                normal: {
                    length: 20,
                    length2: 0,
                    align: 'right',
                    lineStyle: {
                        width: 0,
                    }
                }
            },
            emphasis: {
              label: {
                show: false, //让中间动态文字不显示
              },
            },
            data: [
              {value: this.item.numbers[0], name: '<200' ,itemStyle: {
                  normal: {//颜色渐变
                    color: new echarts.graphic.LinearGradient( //解决颜色渐变
                        0, 0, 0, 1,
                        [{offset: 0, color: '#706502'},
                          {offset: 1, color: '#9C8C03'}])
                  }}
                  },
              {value: this.item.numbers[1], name: '200-500',itemStyle: {
                  normal: {//颜色渐变
                    color: new echarts.graphic.LinearGradient(
                        0, 0, 0, 1,
                        [{offset: 0, color: '#6748B8'},
                          {offset: 1, color: '#47327F'}])
                  }}},
              {value: this.item.numbers[2], name: '500-1000', itemStyle: {
                  normal: {//颜色渐变
                    color: new echarts.graphic.LinearGradient(
                        0, 0, 0, 1,
                        [{offset: 0, color: '#03CCD8'},
                          {offset: 1, color: '#0E6F8C'}])
                  }}},
              {value: this.item.numbers[3] , name: '100-1500',itemStyle: {
                  normal: {//颜色渐变
                    color: new echarts.graphic.LinearGradient(
                        0, 0, 0, 1,
                          [{offset: 0, color: '#FFB156'},
                          {offset: 1, color: '#C1815D'}])
                  }}},
              {value: this.item.numbers[4] , name: '1500>',itemStyle: {
                  normal: {//颜色渐变
                    color: new echarts.graphic.LinearGradient(
                        0, 0, 0, 1,
                        [{offset: 0, color: '#1E9281'},
                          {offset: 1, color: '#135D52'}])
                  }}}
            ],
          },
        ],
      });
      setTimeout(function (){
        // 如果一个页面引入多次相同的子组件，只有一个生效，
          // window.onresize = function () {
          //   myChart.resize();
          // }
          // 可以同时生效
          window.addEventListener('resize', ()=> {
            myChart.resize();
          })
      },200)
    },
  },
  computed: {
    echarts() {
      return "echarts" + Math.random() * 100000;
    },
  },
  mounted: function () {
    console.log(this.item)
    const vm = this;
    vm.$nextTick(() => {
      vm.drawChart();
    });
  },
};
</script>
<style scoped>
.echarts{
  width: 100%;
  height: 100%;
}
.box{
  width: 100%;
  height: 100%;
  color:rgba(rgb(169, 211, 245), .3);
  position: relative;
}
.box_top{
  position: absolute;
  color: #fff;
  text-align: left;
  padding-left: 20px;
  width: 86%;
}
.box_top p{
  line-height: 18px;
  font-size: 12px;
  color: #ACD8E1;
}
.box_top div:nth-of-type(1){float: left}
.box_top div:nth-of-type(2){float: right;text-align: right}
.box_top div p:nth-of-type(1){color: #fff;line-height: 22px;}
.box_top div:nth-of-type(1) p span{
  background: #135D52;
  margin-right: 6px;
  margin-left: -18px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-top: 10px;
}
.box_top div:nth-of-type(2) p span{
  background: #C1815D;
  margin-right: 6px;
  margin-left: -18px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-top: 10px;
}
.box_bottom{
  position: absolute;
  color: #fff;
  text-align: left;
  padding-left: 20px;
  width: 100%;
  display: flex;
  bottom: 0;
}
.box_bottom div{
  flex: 1;
}
.box_bottom p{
  line-height: 18px;
  font-size: 12px;
  color: #ACD8E1;
}
.box_bottom div p:nth-of-type(1){color: #fff;line-height: 22px;}
.box_bottom div p span{
  margin-right: 6px;
  margin-left: -18px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-top: 10px;
}
.box_bottom div:nth-of-type(1) p span{
  background: #9C8C03;
}
.box_bottom div:nth-of-type(2) p span{
  background: #47327F;
}
.box_bottom div:nth-of-type(3) p span{
  background: #0E7391;
}
</style>
