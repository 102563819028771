<template>
  <!-- 最外层div -->
  <div class="left-first">
    <!-- 标题div -->
    <div>
      <p><span class="verticalLine"></span><span> 用户侧可调负荷辅助服务:</span></p>
    </div>
    <div class="center" @click="centerDialogVisible = true">
      <ul>
        <li>
<!--          <img src="../../assets/sign.svg" width="16">-->
          华能江苏售电公司根据客户企业内部分布式电源、自备电厂、充电站、储能等负荷侧资源，为企业提供负荷管理和交易申报，通过响应电网负荷调节需求，获取可调负荷辅助市场调节红利。
        </li>
      </ul>
    </div>
    <!-- 弹窗-->
    <el-dialog :visible.sync="centerDialogVisible" width="1100px" top="30vh" center>
      <div class="popup">
        <p><span class="verticalLine"></span><span>用户侧可调负荷辅助服务</span></p>
        <p>
<!--          电气预防性试验-->
        </p>
        <div class="popup_conter">
          <p>现行电力交易市场在2021年2月正式试运行江苏电力市场用户可调负荷参与辅助服务市场，华能江苏售电公司根据客户企业内部分布式电源、自备电厂、充电站、储能等负荷侧资源，
            为企业提供负荷管理和交易申报，通过响应电网负荷调节需求，获取可调负荷辅助市场调节红利。</p>
          <p>2021年4月3日江苏能源监管办组织清明节日期间4月4日启动短期可调负荷辅助服务市场试运行。4月4日12时至15时，全省共3家一类用户、1家售电公司代理1家二类用户中标，
            累计增加可再生能源消纳84.7万千瓦时，中标电力用户和售电公司共获得辅助服务市场补偿约40万元。华能江苏售电公司通过自身专业性申报，成为4月4日全省唯一一家中标的售电公司。</p>
        </div>
      </div>
    </el-dialog>
  </div>
  <!-- 最外层div over-->
</template>
<script>
export default {
  components:{
  },
  props:{
  },
  data(){
    return{
      item:[
        {lable: '可调节负荷收益', value: '1'},
      ],
      centerDialogVisible: false
    }
  },
  mounted() {
  },
  methods:{
  }
}
</script>
<style lang="less" scoped>
.left-first{
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* 定高 */
  height: 19rem;
  background: rgba(25, 38, 58, 0.8);
  margin-top: 20px;
}
/* 标题 */
.left-first>div:first-child{
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* border: 1px solid rgb(243, 245, 242); */
  /* 定高 */
  height: 2.125rem;
  color: #fff;
  font-size: .875rem;
  display: flex;
  line-height: 2.125rem;
  justify-content: space-between;
  padding:2rem 1.1rem 0rem ;
  background-color: rgba(25, 38, 58, 0.8);
}
.left-first>div:first-child>p:nth-child(1){
  color: #04EBF9;
  position: relative;
  span:nth-of-type(2){
    margin-left: 10px;
  }
}
.left-first>div:first-child>p:nth-child(2){
  color: #ACD8E1;
}
.left-first-left{
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  flex-basis: 46.5%;
  color: #3F6979;
  font-size: 12px;
  /*text-align: left;*/
}
.left-first-left div:nth-of-type(1){
  text-align: left;
  padding: 10px 0 0 20px;
  p{
    line-height: 30px;
  }
}
.left-first-left div:nth-of-type(2){
  text-align: left;
  padding: 10px 0 0 20px;
  li{list-style: none}
  li:nth-of-type(2){margin-top: 10px}
  p{
    line-height: 26px;
  }
}
.left-first-right{
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  flex-basis: 53.5%;
}
/* 内容内部 */
.left-first-left>div{
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /*定宽 */
  width: 13.5rem;
}

.left-first-left>div:first-child{
  background-image: url("../../static/image/left/月度1.png");
  background-repeat: no-repeat;
  /* 定高 */
  height: 6.5rem;
  margin-top: .5rem;
}
.left-first-left>div:nth-child(2){
  background-image: url("../../static/image/left/月度2.png");
  background-repeat: no-repeat;
  /* 定高 */
  height: 8.7rem;
}
.center{
  height: 100%;
}
.center ul{
  padding-top: 60px;
}
.center li{
  list-style: none;
  text-align: left;
  color: #ACD8E1;
  font-size: 16px;
  line-height: 36px;
  margin: 0 50px;
  cursor: pointer;
}
.center li img{
  margin-right: 20px;
  vertical-align: middle;
}
.verticalLine{
  position: absolute;
  display: inline-block;
  border-right: 2px solid;
  height: 16px;
  top: 10px;
  color: #04EBF9;
}
/deep/ .el-dialog{
  border-radius: 10px;
}
/deep/ .el-dialog__header{
  display: none;
}
/deep/ .el-dialog__body{
  min-height: 300px;
  padding: 25px 40px 10px !important;
  color: #333;
}
.popup{
  position: relative;
}
.popup p{
  margin: 20px 0;
  line-height: 40px;
  font-size: 16px;
  //font-weight: 600;
  font-family: '微软雅黑';
  text-indent:2em;
}
.popup .verticalLine{
  top: 11px;
  height: 20px;
  color: #2BA990;
}
.popup p span:nth-of-type(2){
  margin-left: 20px;
  color: #666;
  font-size: 18px;
}
.popup>p:nth-of-type(2){
  color: #333;
  font-weight: 800;
  font-size: 16px;
  margin-top: 30px;
  line-height: 30px;
  padding: 0 6px;
  border-bottom: 1px solid #ccc;
}
.popup p:nth-of-type(3){
  margin-top: 20px;
}
.popup_conter{
  height: 360px;
  overflow-y: auto;
}
/* 设置滚动条的样式 */
.popup_conter::-webkit-scrollbar {
  width:12px;
}
/* 滚动槽 */
.popup_conter::-webkit-scrollbar-track {
  -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
  border-radius:10px;
}
/* 滚动条滑块 */
.popup_conter::-webkit-scrollbar-thumb {
  border-radius:10px;
  background:rgba(48, 46, 53, 0.5);
  -webkit-box-shadow:inset 006px rgba(0,0,0,0.5);
}
.popup_conter::-webkit-scrollbar-thumb:window-inactive {
  background:rgba(39, 57, 92, 0.4);
}
</style>
