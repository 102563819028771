<template>
  <!-- 最外层div -->
  <div class="map">
    <!-- 解决高度坍塌 -->
    <table></table>
    <!-- 半透明底内容 -->
    <div>
      <!-- 图例 -->
      <p>
        <span>●</span>签约客户(户)
      </p>
      <!-- 可点击大区部分 -->
      <ul class="area-out">
        <li v-for="(obj,index) of areaList" :key="index" @click="areaClicked(index,obj)"
        @mouseenter="areaHover(obj,true)"
        @mouseleave="areaHover(obj,false)">
          <span :style="{color: listColor[index]}">●</span> {{obj.areaName}}
        </li>
      </ul>    <!-- 可点击大区部分 over-->

      <!-- 左下角部分 -->
      <ul class="bottom-left">
        <li v-for="(obj,index) of firmList" :key="index">
          <!-- 动态改变图片 -->
          <img :src="obj.img" alt="图裂">
          {{obj.text}}
        </li>
      </ul>  <!-- 左下角部分 over-->

      <!-- 图例 over-->

      <!-- 地图中电厂标识部分 -->
      <div class="map-firm">
        <!-- 遍历产生各个电厂 -->
        <div v-for="(obj,index) of mapFirms" :key="index" :style="{top:obj.topPosition,left:obj.leftPosition}">
          <!-- 增加外框 用于实现定位 -->
          <div>
            <el-tooltip effect="dark" :placement="obj.position">
            <!-- 插槽部分 自定义换行样式 -->
            <div slot="content" class="tip-text">
              <p v-for="(text,index) of obj.textArr"  :key="`文本${index}`">
                {{text}}
              </p>
            </div>
            <!-- 会被hover的内容部分 -->
            <div class="tip-hover">
              <!-- 动态图片 -->
              <img :src="require(`../../static/image/center/map/svg/${obj.img}.svg`)" alt="图裂" :style="{width:obj.imgWidth != undefined ? obj.imgWidth :''}">
              <!-- 底部圆圈 (暂时不用)-->
              <!-- <span :style="{color:obj.color,top:obj.top,left:obj.left}">
                ●
              </span> -->
            </div>
          </el-tooltip>
          </div>          <!-- 增加外框 用于实现定位 over-->

        </div>        <!-- 遍历各个电厂 over-->

      </div>    <!-- 地图中电厂标识部分 over-->


      <!-- 拼图 -->
      <div class="blue">
        <!-- 遍历产生底部hover显示的蓝色拼图 -->
        <div v-for="i of 13" :key="i" v-show="blueShow[i-1]">
          <!-- !!动态改变图片 !!!-->
          <img :src="require(`../../static/image/center/map/${i}.png`)" alt="sorry 图裂了">
        </div>
      </div>    <!-- 拼图 over-->

      <!-- 地名 圆圈  -->
      <div class="contain">
        <!-- 遍历产生不同城市的部分 -->
        <!-- <div v-for="(i,index) of city" :key="index" @mouseover="circle_in($event,index)" @mouseout="circle_out($event,index)"> -->

        <!-- 移入事件改为点击事件 -->
        <div v-for="(i,index) of city" :key="index" @click="mapClicked(index)"
        @mouseover="circle_in($event,index)" @mouseout="circle_out($event,index)">

          <!-- 地名和圆圈 -->
          <div class="T-C">
            <p>{{i}}</p>
            <!-- 圆圈部分 -->
            <div class="circle" ref="circle">
              <p>{{customs[index].custom}}</p>
            </div>      <!-- 圆圈部分 over-->
          </div>    <!-- 地名和圆圈 over-->

        </div>     <!-- 遍历产生不同城市的部分 over-->
      </div>      <!-- 地名 圆圈 over-->

      <!-- 弹出框 -->
      <div class="square-all">
        <!-- 遍历框框部分 -->
          <div class="square" v-for="(i,index) of city" :key="index" v-show="squareShow[index]">
            <!-- 上部 -->
            <div class="square-top">
              <div>
                {{i}}区域：
              </div>
              <div></div>
            </div>
            <!-- 下部 -->
            <div>
              <p>
                客户数量：<span>{{customs[index].custom}}</span> 户
              </p>
              <p>
                签约电量：<span>{{customs[index].electric}}</span> 万kWh
              </p>
            </div>
          </div>      <!-- 框框部分 over-->
      </div>
      <!-- 弹出框 over-->

      <!-- 显示详情和排名的弹窗组件 -->
      <Dialog :isOpen="diaOpen"
      @diaClose="diaClose"
      :diaData="areaInfo">
      </Dialog>     <!-- 显示详情和排名的弹窗 over -->

    </div>    <!-- 半透明底内容 over-->
  </div>    <!-- 最外层div over-->
</template>


<script>
// 引入axios请求组件
import * as method from "@/api";
// 引入弹窗组件
import Dialog from "../MapDialog/dialog"

  export default{
    components:{
      Dialog
    },
    data(){
      return{
        // 控制底部蓝色
        // 0.徐州 1.连云港 2.宿迁 3.淮安 4.盐城 5.扬州 6.泰州 7.南京 8.镇江 9.常州 10.无锡 11.苏州 12.南通
        blueShow:[
          false,false,false,false,false,false,false,false,false,false,false,false,false,
        ],
        city:[
          "徐州","连云港","宿迁","淮安","盐城","扬州","泰州","南京","镇江","常州","无锡","苏州","南通"
        ],
        squareShow:[
          false,false,false,false,false,false,false,false,false,false,false,false,false,
        ],
        // 模拟客户数和签约电量
        customs:[
          {electric:"",custom:"",city:"徐州",code:""},
          {electric:"",custom:"",city:"连云港",code:""},
          {electric:"",custom:"",city:"宿迁",code:""},
          {electric:"",custom:"",city:"淮安",code:""},
          {electric:"",custom:"",city:"盐城",code:""},
          {electric:"",custom:"",city:"扬州",code:""},
          {electric:"",custom:"",city:"泰州",code:""},
          {electric:"",custom:"",city:"南京",code:""},
          {electric:"",custom:"",city:"镇江",code:""},
          {electric:"",custom:"",city:"常州",code:""},
          {electric:"",custom:"",city:"无锡",code:""},
          {electric:"",custom:"",city:"苏州",code:""},
          {electric:"",custom:"",city:"南通",code:""},
        ],
         // 可点击大部分数据(后期属性名直接选用后端返回的)
        areaList:[
          // {
          //   areaName:"淮安大区",
          // },
          // {
          //   areaName:"南通大区",
          // },
          // {
          //   areaName:"常州大区",
          // },
          // {
          //   areaName:"苏州大区",
          // },
          // {
          //   areaName:"南京大区",
          // },
        ],
        // 圆圈颜色部分 对应大区顺序(默认是五个)
        listColor:[
          "rgb(64,59,58)","rgb(14,115,145)","rgb(18,86,77)","rgb(39,41,81)","rgb(10,141,153)"
        ],
        // 电厂图标显示容器
        firmList:[
          {
            text:"风电",
            img:require("../../static/image/center/map/svg/feng.svg"),
          },
          {
            text:"光伏",
            img:require("../../static/image/center/map/svg/guang.svg"),
          },
          {
            text:"清洁火电",
            img:require("../../static/image/center/map/svg/qingjie.svg"),
          },
          {
            text:"气电",
            img:require("../../static/image/center/map/svg/qi.svg"),
          },
          {
            text:"垃圾发电",
            img:require("../../static/image/center/map/svg/laji.svg"),
          },
          {
            text:"储能",
            img:require("../../static/image/center/map/svg/chuneng.svg"),
          },
          {
            text:"带污泥耦合",
            img:require("../../static/image/center/map/svg/qingjie02.svg"),
          },
        ],
        // 地图中 电厂标识
        mapFirms:[
          {
            // 图片类型
            img:"feng",
            // tooltip框内容
            textArr:["铜山风电"],
            // tooltip显示的位置
            position:"left",
            // 底部圆圈颜色
            color:"#23FBD0",
            // 底部圆圈绝对定位位置(暂时用不上)
            top:"25px",
            left:"10px",
            // 图标整体的绝对定位位置
            topPosition:"45px",
            leftPosition:"145px",
          },
          {
            img:"guang",
            textArr:["灌云光伏"],
            // tooltip显示的位置
            position:"right",
            // 底部圆圈颜色
            color:"#FAA60A",
            top:"22px",
            left:"9px",
            imgWidth:"22px",
            topPosition:"235px",
            leftPosition:"450px",
          },
          {
            img:"qingjie",
            textArr:["灌云热电"],
            // tooltip显示的位置
            position:"right",
            // 底部圆圈颜色
            color:"#03ECFA",
            top:"25px",
            left:"9px",
            imgWidth:"22px",
            topPosition:"205px",
            leftPosition:"465px",
          },
          {
            img:"guang",
            textArr:["泗洪渔光互补光伏"],
            // tooltip显示的位置
            position:"left",
            // 底部圆圈颜色
            color:"#FAA60A",
            top:"22px",
            left:"9px",
            imgWidth:"22px",
            topPosition:"310px",
            leftPosition:"260px",
          },
          {
            img:"guang",
            textArr:["淮阴光伏"],
            // tooltip显示的位置
            position:"left",
            // 底部圆圈颜色
            color:"#FAA60A",
            top:"22px",
            left:"9px",
            imgWidth:"22px",
            topPosition:"280px",
            leftPosition:"365px",
          },
          {
            img:"qingjie02",
            textArr:["淮阴电厂"],
            // tooltip显示的位置
            position:"right",
            // 底部圆圈颜色
            color:"#03ECFA",
            top:"25px",
            left:"9px",
            imgWidth:"22px",
            topPosition:"280px",
            leftPosition:"395px",
          },
          {
            img:"feng",
            textArr:["灌云海上风电","灌云海上风电二期","射阳海上风电","大丰海上风电","如东海上风电","盛东如东海上风电","盛东如东海上风电二期","启东海上风电"],
            position:"right",
            color:"#23FBD0",
            top:"25px",
            left:"10px",
            topPosition:"350px",
            leftPosition:"565px",
          },
          // 8.扬州风电
          {
            img:"feng",
            textArr:["仪征风电"],
            position:"right",
            color:"#23FBD0",
            top:"25px",
            left:"10px",
            topPosition:"480px",
            leftPosition:"310px",
          },
          // 左侧风
          {
            img:"feng",
            textArr:["六合风电"],
            position:"left",
            color:"#23FBD0",
            top:"25px",
            left:"10px",
            topPosition:"435px",
            leftPosition:"255px",
          },
          // 10.左侧清洁
          {
            img:"qingjie",
            textArr:["南京热电","南京电厂","金陵电厂"],
            // tooltip显示的位置
            position:"left",
            // 底部圆圈颜色
            color:"#03ECFA",
            top:"25px",
            left:"9px",
            imgWidth:"22px",
            topPosition:"495px",
            leftPosition:"200px",
          },
          {
            img:"qi",
            textArr:["金陵燃机"],
            position:"left",
            color:"#23FB89",
            top:"25px",
            left:"10px",
            topPosition:"470px",
            leftPosition:"255px",
          },
          {
            img:"laji",
            textArr:["江北垃圾发电"],
            // tooltip显示的位置
            position:"left",
            // 底部圆圈颜色
            color:"#FAD816",
            top:"20px",
            left:"9px",
            // img图片尺寸(改变横向 纵向自动变)
            imgWidth:"18px",
            topPosition:"610px",
            leftPosition:"225px",
          },
          // 13.南通清洁
          {
            img:"qingjie",
            textArr:["南通电厂","苏通电厂"],
            // tooltip显示的位置
            position:"right",
            // 底部圆圈颜色
            color:"#03ECFA",
            top:"25px",
            left:"9px",
            imgWidth:"22px",
            topPosition:"520px",
            leftPosition:"450px",
          },
          // 14.南通风电
          {
            img:"feng",
            textArr:["如东风电","启东风电"],
            position:"right",
            color:"#23FBD0",
            top:"25px",
            left:"10px",
            topPosition:"670px",
            leftPosition:"550px",
          },
          // 15.左侧气电
          {
            img:"qi",
            textArr:["江阴燃机"],
            position:"left",
            color:"#23FB89",
            top:"25px",
            left:"10px",
            imgWidth:"22px",
            topPosition:"640px",
            leftPosition:"300px",
          },
          {
            img:"chuneng",
            textArr:["金坛盐穴储能电站"],
            position:"left",
            color:"#23FB89",
            top:"22px",
            left:"9px",
            imgWidth:"20px",
            topPosition:"600px",
            leftPosition:"335px",
          },
          // 15.右下三个
          {
            img:"qi",
            textArr:["苏州燃机"],
            position:"left",
            color:"#23FB89",
            top:"25px",
            left:"10px",
            imgWidth:"22px",
            topPosition:"690px",
            leftPosition:"330px",
          },
          {
            img:"qingjie02",
            textArr:["苏州煤机","太仓电厂"],
            // tooltip显示的位置
            position:"right",
            // 底部圆圈颜色
            color:"#03ECFA",
            top:"25px",
            left:"9px",
            imgWidth:"22px",
            topPosition:"615px",
            leftPosition:"433px",
          },
          {
            img:"guang",
            textArr:["太仓灰场光伏"],
            // tooltip显示的位置
            position:"right",
            // 底部圆圈颜色
            color:"#FAA60A",
            top:"22px",
            left:"9px",
            imgWidth:"22px",
            topPosition:"665px",
            leftPosition:"465px",
          },
        ],

        // 控制弹窗显/隐
        diaOpen:false,
        // 弹窗内容保存容器
        areaInfo:{
          // areasName:"南京大区",
          // // 客户数量
          // caTotal:8,
          // // 签约电量
          // eleTotal: 27255,
          // // 前五排名
          // clients:[
          //   {
          //     // 客户名称
          //     clientName:"江苏省建筑工程集团有限公司",
          //     // 签约电量(合同电量)
          //     contractElectricity: 10000.56
          //   },
          //   {
          //     clientName:"大客户A",
          //     contractElectricity: 5566
          //   },
          //   {
          //     clientName:"大客户B",
          //     contractElectricity: 5555
          //   },
          //   {
          //     clientName:"大客户C",
          //     contractElectricity: 1524
          //   },
          //   {
          //     clientName:"大客户D",
          //     contractElectricity: 1520
          //   },
          // ]
        }
      }
    },
    methods:{
      // 圆圈部分的移入事件
      circle_in(e,i){
        e.stopPropagation();
        // 如果是有客户数的才显示方框(暂不使用 改为点击弹出弹窗事件)
        // if(this.customs[i].custom !=""){
        //   this.squareShow.splice(i,1,true);
        // }
        // 地图底色
        this.blueShow.splice(i,1,true);
      },
      // 移出事件
      circle_out(e,i){
        // 如果是有客户数的才显示方框(暂不使用 改为点击弹出弹窗事件)
        e.stopPropagation();
        // if(this.customs[i].custom !=""){
        //   this.squareShow.splice(i,1,false);
        // }

        this.blueShow.splice(i,1,false);
      },
      // 右侧大区的移入事件
      areaHover(obj,state){
        // 获取当前移入大区所包含城市
        for (let area of obj.areaNames) {
          // 对比城市文本容器 找到是第几个城市(城市顺序对应地图蓝底顺序)
          this.city.forEach((text,index) => {
            if(area.indexOf(text) != -1){
              // 找到当前大区对应的蓝底的下标 进行动效
              this.blueShow.splice(index,1,state);
            }
          })
        }
      },
      // 获取客户数量 并根据数量确定圈圈大小
      get_custom(){
        // 小于100 2.5rem  每多100增加.5rem
      // 发送请求获取数据 取出数据 放入data中对象/数组
        method.getMethod("/sdyw/analysis/map",{
        }).then(res=>{
          // 创建容器
          let objHolder={}
          for(let c=0;c<this.customs.length;c++){
            objHolder={}
            // 获取界面上城市顺序
            let getCity=this.customs[c].city;
            // 放入临时对象容器
            objHolder.city=getCity;
            // 找到结果中对应的
             for (let obj of res) {
               if(obj.areaName){
                 if(obj.areaName.indexOf(getCity)!=-1){
                    //  签约电量(暂不展示)
                    objHolder.electric=obj.totalNumber;
                    //  签约人数
                    objHolder.custom=obj.totalCount;
                    //  该城市对应code
                    objHolder.code = obj.area;
                    // 只会取返回数据中存在的城市 替换原容器中其数据对象
                   this.customs.splice(c,1,objHolder)
                 }
               }
             }
          }
          // console.log(this.customs);
          setTimeout(()=>{
            let count=0;
          // 根据里面的数值来确认圆圈大小
          let circles=this.$refs.circle;
          for (let div of circles) {
            // 若没有客户 则圆圈隐藏
            if(div.innerText==""){
              div.style.visibility="hidden";
            }
            count= parseInt(div.innerText);
            if(count>100000){
              div.style.width=`4rem`
              // console.log(div.style.width);
              div.style.height=`4rem`
            }else if(count>10000){
              div.style.width=`3.5rem`
              div.style.height=`3.5rem`
            }else if(count>1000){
              div.style.width=`3rem`
              div.style.height=`3rem`
              /*div.style.width=`${0.5*((count-1000)/100)+2.5}rem`
              // console.log(div.style.width);
              div.style.height=`${0.5*((count-1000)/100)+2.5}rem`*/
            }else if(count>100) {
              div.style.width="2.5rem";
              div.style.height="2.5rem";
            } else {
              div.style.width="2.5rem";
              div.style.height="2.5rem";
            }
          }
          },150)

        })

      },
      // 获取右侧大区
      getArea(){
        method.getMethod("/sdyw/big/area/list",{
        }).then(res=>{
          // 放入显示容器(其中 id字段用于获取大区内排名数据时传出)
          this.areaList = res;
          console.log(this.areaList);
        })
      },
      // 右侧大区被点击触发
      areaClicked(index,obj){
        // 根据被点击的大区 传参获取弹窗数据
        this.getDialogData("bigArea",obj.id);
      },
      // 地图上区域被点击后触发
      mapClicked(index){
        // 传入当前点击地名 在city数组中的下标(city数组中的城市顺序与customs中相同)
        // 若该点击区域有客户数量 则获取数据弹出弹窗
        if(this.customs[index].custom !== ""){
          this.getDialogData("city",this.customs[index].code);
        };
      },
      // 获取对应弹窗中要展示的对应数据
      getDialogData(state,code){
        method.getMethod("/sdyw/analysis/client/city",{
          // 传入大区 bigArea   /城市  city
          type:state,
          // 需要获取数据的地域id
          area:code
        }).then(res=>{
          // 更新弹窗组件中显示容器内容
          this.areaInfo = res;
          console.log(this.areaInfo);
          // 打开弹窗
          this.diaOpen = true;
        })
      },
      // 弹窗关闭时 弹窗子组件中调用
      diaClose(){
        // 将弹窗子组件的关闭状态同步到本组件
        this.diaOpen = false;
        // 清空本组件中的弹窗数据容器内容
        this.areaInfo = {};
      },
    },
    mounted(){
      this.get_custom();
      this.getArea();
    }
  }
</script>

<style scoped>
  ul,li{
    list-style:none;
  }
  .map{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* border: 1px solid rgb(72, 245, 4); */
    /* 定高定宽 */
    height: 52rem;
    width: 47rem;
    background-image: url("../../static/image/center/map/bg_border.png");
    background-repeat: no-repeat;
    margin: 0 auto;
    margin-top: .6rem;
    padding: .9rem 1.1rem .9rem
  }
  .map>div{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* border: 1px solid rgb(218, 230, 213); */
    background-image: url("../../static/image/center/map/map.png");
    background-repeat: no-repeat;
    background-position: 7.1rem;
    /* 定高 */
    height: 100%;
    background-color: rgba(23, 39, 53, 0.5);
    /* 相对定位 */
    position: relative;
  }
  .map>div>p{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-size: .875rem;
    color: #fff;
    text-align: right;
    padding-right: 2rem;
    padding-top:2rem ;
  }
  .map>div>p>span{
    color:#FFB866;
    margin-right:.5rem ;
    font-size: 1.3rem;
  }

  /* 可点击大区部分 */
  .area-out{
    margin-top: 1.25rem;
    /* border: 1px solid rgb(158, 241, 4); */
    /* 定宽 */
    width:7.5rem;
    /* 右浮动 */
    float: right;
    margin-right: 5px;
  }
  .area-out ul,.area-out li{
    list-style: none;
  }
  .area-out li{
    /* 定高 */
    height: 1.875rem;
    line-height: 1.875rem;
    text-align: left;
    font-size: 14px;
    padding-left: .9rem;
    color: #fff;
    border-radius: 3px;
  }
  .area-out li>span{
    font-size: 1.3rem;
    margin-right:.2rem ;
  }
  .area-out li:hover{
    cursor: pointer;
    background-color: rgba(9, 201, 226, 0.5);
  }


  /* 左下角部分 */
  .bottom-left{
    /* 绝对定位 */
    position: absolute;
    padding-left: 1.875rem;
    padding-bottom: .625rem;
    bottom: 0px;
    /* border: 1px solid rgb(245, 7, 7); */
    color: #738F9B;
    font-size: 14px;
  }
  .bottom-left>li{
    /* 定高 */
    height: 2rem;
    text-align: left;
    font-size: 10px;
  }
  .bottom-left>li>img{
    width: 16px;
    height: 20px;
    margin-right: .5rem;
    vertical-align: bottom;
    /* border: 1px solid #ccc; */
  }


  /* 地图中的电厂标识部分 */
  .map-firm>div{
    /* 定宽 */
    width: 29px;
    /* border: 1px solid rgb(206, 2, 247); */
    /* 绝对定位 */
    position: absolute;
  }
  /* 内部包裹部分 相对定位 */
  .map-firm>div>div{
    /* border: 1px solid rgb(161, 241, 11); */
    position: relative;
  }
  /* 弹窗部分 */
  .tip-text>p{
    font-size: 14px;
    /* text-align: center; */
  }
  .tip-text>p:not(:first-child){
    margin-top: .5rem;
  }
  .tip-hover>img{
    /* 定宽定高 */
    width: 28px;
    height: 29px;
  }
  .tip-hover>span{
    display: inline-block;
    font-size: 1.1rem;
    /* border: 1px solid #ccc; */
    /* 绝对定位 */
    position: absolute;
  }



  /* 浅蓝拼图 */
  .blue>div{
    /* 绝对定位 */
    position: absolute;
  }
  .blue>div:first-child{
    left: 7rem;
    top: 1.1rem;
  }
  .blue>div:nth-child(2){
    right: 12.9rem;
    top: 7.2rem;
  }
  .blue>div:nth-child(3){
    left: 13.3rem;
    top: 12.159rem;
  }
  .blue>div:nth-child(4){
    left: 14rem;
    top: 16.05rem;
  }
  .blue>div:nth-child(5){
    right: 10rem;
    top: 14.6rem;
  }
  .blue>div:nth-child(6){
    left: 17.5rem;
    bottom: 15.8rem;
  }
  .blue>div:nth-child(7){
    right: 14.2rem;
    bottom: 11.5rem;
  }
  .blue>div:nth-child(8){
    left: 11.6rem;
    bottom: 9.1rem;
  }
  .blue>div:nth-child(9){
    left: 15.3rem;
    bottom: 11.3rem;
  }
  .blue>div:nth-child(10){
    left: 15.1rem;
    bottom: 6.8rem;
  }
  .blue>div:nth-child(11){
    left: 17.1rem;
    bottom: 6rem;
  }
  .blue>div:nth-child(12){
    right: 12.2rem;
    bottom: .8rem;
  }
  .blue>div:nth-child(13){
    right: 7.35rem;
    bottom: 3.6rem;
  }
  /* 地名 圆圈 弹出框 */
  .contain>div{
    /* border: 1px solid rgb(226, 21, 21); */
    /* 绝对定位 */
    position: absolute;
  }
  .T-C:hover{
    cursor: pointer;
  }
  .T-C>p{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    color: #fff;
    font-size: .875rem;
    text-align: left;
    margin-bottom: .5rem;
    padding-left:.35rem ;
  }
  .circle{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    color: #fff;
    border: 2px solid rgba(224, 162, 27, 0.6);
    box-shadow: .08rem .08rem .8rem .05rem rgba(245, 199, 73,0.7) inset;
    border-radius: 50%;
    /* 相对定位 */
    position: relative;
    /* 默认是小于100的大小 */
    /*width: 2.5rem;
    height: 2.5rem;*/
    -webkit-animation: warn 3s ease-out;
    -moz-animation: warn 3s ease-out;
    animation: warn 3s ease-out;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
  .circle>p{
    /* background-color: blue; */
    /* 永恒居中 */
    /* 绝对定位 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  /* 框框 */
  .square,.square>div,.square-top>div{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* border: 1px solid rgb(218, 230, 213); */

  }
  .square{
    /* border: 1px solid rgb(218, 230, 213); */
    /* 绝对定位 */
    position: absolute;
    font-size: .875rem;
    color: #fff;
  }
  .square-top{
    display: flex;
    /* border: 1px solid rgb(81, 233, 15); */
  }
  .square-top>div:first-child{
    /* border: 1px solid rgb(83, 235, 18); */
    background-image: url("../../static/image/center/map/square_bg.png");
    background-repeat: no-repeat;
    /* 定宽定高 */
    height: 3.25rem;
    width: 7.55rem;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding-top:.7rem ;
    text-align: left;
    padding-left: 1rem;
  }
  .square-top>div:nth-child(2){
    /* 定高 */
    height: 1.73rem;
    align-self: flex-end;
    border-top: 1px solid rgb(171,184,185,.8);
    border-right: 1px solid rgb(171,184,185,.8);
    border-top-right-radius: 5%;
    flex-grow: 1;
    background-color: rgba(0,0,0,.8);
  }
  .square>div:nth-child(2){
    border-right: 1px solid rgba(187, 196, 197, 0.8);
    border-left: 1px solid rgb(187, 196, 197, 0.8);
    border-bottom: 1px solid rgb(187, 196, 197, 0.8);
    background-color:  rgba(0,0,0,.8);
    /* 定高 */
    height: 6rem;
  }
  .square>div:nth-child(2)>p{
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: left;
  }
   .square>div:nth-child(2)>p>span{
     font-size: 1.25rem;
     font-weight: 600;
   }
  .square>div:nth-child(2)>p:first-child{
    margin-bottom: 1rem;
  }
  /* 标题和圆圈位置 */
  .contain>div:first-child{
    top:8.6rem;
    left:13.5rem;
  }
  .contain>div:nth-child(2){
    top:10.2rem;
    right:16rem;
  }
  .contain>div:nth-child(3){
    top:15.5rem;
    left:19.5rem;
  }
  .contain>div:nth-child(4){
    top:20rem;
    left:21.8rem;
  }
  .contain>div:nth-child(5){
    bottom:22.5rem;
    right:12.5rem;
  }
  .contain>div:nth-child(6){
    bottom:17.5rem;
    left:21.5rem;
  }
  .contain>div:nth-child(7){
    bottom:18rem;
    right:17rem;
  }
  .contain>div:nth-child(8){
    bottom:14.2rem;
    left:14.5rem;
  }
  .contain>div:nth-child(9){
    bottom:13rem;
    left:20rem;
  }
  .contain>div:nth-child(10){
    bottom:9rem;
    left:16rem;
  }
  .contain>div:nth-child(11){
    bottom:7.5rem;
    left:24rem;
  }
  .contain>div:nth-child(12){
    bottom:2rem;
    right:16rem;
  }
  .contain>div:nth-child(13){
    bottom:9.5rem;
    right:12rem;
  }
  /* 框框位置 */
  .square-all>div:nth-child(1){
    top:13.4rem;
    left:13.5rem;
  }
  .square-all>div:nth-child(2){
    top:15rem;
    right:9rem;
  }
  .square-all>div:nth-child(3){
    top:20.3rem;
    left:19.5rem;
  }
  .square-all>div:nth-child(4){
    top:24.8rem;
    left:21.8rem;
  }
  .square-all>div:nth-child(5){
    bottom:12.3rem;
    right:4.5rem;
  }
  .square-all>div:nth-child(6){
    bottom:7.5rem;
    left:21.5rem;
  }
  .square-all>div:nth-child(7){
    bottom:7.8rem;
    right:9rem;
  }
  .square-all>div:nth-child(8){
    bottom:4.2rem;
    left:14.5rem;
  }
  .square-all>div:nth-child(9){
    bottom:2.8rem;
    left:20rem;
  }
  .square-all>div:nth-child(10){
    bottom:-.5rem;
    left:15.5rem;
  }
  .square-all>div:nth-child(11){
    bottom:.0rem;
    left:28.2rem;
  }
  .square-all>div:nth-child(12){
    bottom:0rem;
    right:3.8rem;
  }
  .square-all>div:nth-child(13){
    bottom:3rem;
    right:-.5rem;
  }
  @keyframes warn {
    0% {
      transform: scale(0.6);
      opacity: 0.6;
    }
    25% {
      transform: scale(0.7);
      opacity: 0.7;
    }
    50% {
      transform: scale(0.8);
      opacity: 0.8;
    }
    75% {
      transform: scale(0.9);
      opacity: 0.9;
    }
    100% {
      transform: scale(1);
      opacity: 0.0;
    }
  }
  @-webkit-keyframes "warn" {
    0% {
      -webkit-transform: scale(0.6);
      opacity: 0.6;
    }
    25% {
      -webkit-transform: scale(0.7);
      opacity: 0.7;
    }
    50% {
      -webkit-transform: scale(0.8);
      opacity: 0.8;
    }
    75% {
      -webkit-transform: scale(0.9);
      opacity: 0.9;
    }
    100% {
      -webkit-transform: scale(1);
      opacity: 0.0;
    }
  }
</style>
