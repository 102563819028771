<template>
<!-- 最外层div -->
  <div class="left-first">
    <!-- 标题div -->
    <div>
      <p><span class="verticalLine"></span><span>增值服务项目:</span></p>
    </div>
    <div class="center" @click="centerDialogVisible = true">
      <ul>
        <li>
          <!--<img src="../../assets/sign.svg" width="16">-->
          公司为签约企业提供变压器全套电气预防性试验、安全工器具检测、电能质量治理和改善、配电设备维护、 维修托管及其他用电咨询服务。
        </li>
      </ul>
    </div>
    <!-- 弹窗-->
    <el-dialog :visible.sync="centerDialogVisible" width="1100px" top="24vh" center>
      <div class="popup">
        <p><span class="verticalLine"></span><span>增值服务项目</span></p>
        <p>公司为签约企业提供丰富的电力增值服务，具体服务如下：</p>
        <div class="popup_conter">
        <p>1． <span>变压器全套电气预防性试验。</span>提供包括变压器、断路器高、低压系统、变压器油化等试验。</p>
        <p>2． <span>安全工器具检测。</span>包含验电器、接地线、绝缘杆、绝缘靴、绝缘手套、绝缘胶垫灭火器等安全工器具的检测。</p>
        <p>3． <span>电能质量治理和改善。</span>通过对电压、电流、频率有效值，功率、功率因数，电压、电流波形和相位，谐波频谱图和畸变率等电能质量问题的解决治理，改善用电环境。</p>
        <p>4． <span>配电设备维护、 维修托管。</span>对企业配电场所、电气设备和安全器具进行每三个月一次的全面检査并提供检查报告和整改建议。对企业配电场所、电气设备提供清理维修等维护工作。每年为企业提供检查、24小时紧急维修处理服务。</p>
        <p>5． <span>其他用电咨询服务。</span>根据企业需求定制相关电力服务。</p>
        </div>
      </div>
    </el-dialog>
  </div>
<!-- 最外层div over-->
</template>
<script>
export default {
  components:{
  },
  props:{
  },
  data(){
    return{
      item:[
        {lable: '电气预防性试验', value: '1'},
        {lable: '工器具检测', value: '2'},
        {lable: '电气预防性试验', value: '3'}
      ],
      centerDialogVisible: false
    }
  },
  mounted() {
  },
  methods:{
  }
}
</script>
<style lang="less" scoped>
  .left-first{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* 定高 */
    height: 19rem;
    background: rgba(25, 38, 58, 0.8);
  }
  /* 标题 */
  .left-first>div:first-child{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* border: 1px solid rgb(243, 245, 242); */
    /* 定高 */
    height: 2.125rem;
    color: #fff;
    font-size: .875rem;
    display: flex;
    line-height: 2.125rem;
    justify-content: space-between;
    padding:2rem 1.1rem 0rem ;
    background-color: rgba(25, 38, 58, 0.8);
  }
  .left-first>div:first-child>p:nth-child(1){
    color: #04EBF9;
    position: relative;
    span:nth-of-type(2){
      margin-left: 10px;
    }
  }
  .left-first>div:first-child>p:nth-child(2){
    color: #ACD8E1;
  }
  .left-first-left{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    flex-basis: 46.5%;
    color: #3F6979;
    font-size: 12px;
    /*text-align: left;*/
  }
  .left-first-left div:nth-of-type(1){
    text-align: left;
    padding: 10px 0 0 20px;
    p{
      line-height: 30px;
    }
  }
  .left-first-left div:nth-of-type(2){
    text-align: left;
    padding: 10px 0 0 20px;
    li{list-style: none}
    li:nth-of-type(2){margin-top: 10px}
    p{
      line-height: 26px;
    }
  }
  .left-first-right{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    flex-basis: 53.5%;
  }
  /* 内容内部 */
  .left-first-left>div{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /*定宽 */
    width: 13.5rem;
  }

  .left-first-left>div:first-child{
    background-image: url("../../static/image/left/月度1.png");
    background-repeat: no-repeat;
    /* 定高 */
    height: 6.5rem;
    margin-top: .5rem;
  }
  .left-first-left>div:nth-child(2){
    background-image: url("../../static/image/left/月度2.png");
    background-repeat: no-repeat;
    /* 定高 */
    height: 8.7rem;
  }
  .center{
    height: 100%;
  }
  .center ul{
    padding-top: 60px;
  }
  .center li{
    list-style: none;
    text-align: left;
    color: #ACD8E1;
    font-size: 16px;
    line-height: 36px;
    margin: 0 50px;
    cursor: pointer;
  }
  .center li img{
    margin-right: 20px;
    vertical-align: middle;
  }
  .verticalLine{
    position: absolute;
    display: inline-block;
    border-right: 2px solid;
    height: 16px;
    top: 10px;
    color: #04EBF9;
  }
  /deep/ .el-dialog{
    border-radius: 10px;
  }
  /deep/ .el-dialog__header{
    display: none;
  }
  /deep/ .el-dialog__body{
    min-height: 300px;
    padding: 25px 40px 10px !important;
    color: #333;
  }
  .popup{
    position: relative;
  }
  .popup p{
    margin: 20px 0;
    line-height: 40px;
    font-size: 16px;
    //font-weight: 600;
    font-family: '微软雅黑';
  }
  .popup p span{
    color: #3a8ee6;
    font-weight: 600;
  }
  .popup .verticalLine{
    top: 11px;
    height: 20px;
    color: #2BA990;
  }
  .popup p span:nth-of-type(2){
    margin-left: 20px;
    color: #666;
    font-size: 18px;
  }
  .popup>p:nth-of-type(2){
    color: #333;
    font-weight: 800;
    font-size: 16px;
    margin-top: 30px;
    line-height: 30px;
    padding: 0 6px;
    border-bottom: 1px solid #ccc;
  }
  .popup p:nth-of-type(3){
    margin-top: 20px;
  }
  .popup_conter{
    //height: 500px;
    overflow-y: auto;
  }
  /* 设置滚动条的样式 */
  .popup_conter::-webkit-scrollbar {
    width:12px;
  }
  /* 滚动槽 */
  .popup_conter::-webkit-scrollbar-track {
    -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
    border-radius:10px;
  }
  /* 滚动条滑块 */
  .popup_conter::-webkit-scrollbar-thumb {
    border-radius:10px;
    background:rgba(48, 46, 53, 0.5);
    -webkit-box-shadow:inset 006px rgba(0,0,0,0.5);
  }
  .popup_conter::-webkit-scrollbar-thumb:window-inactive {
    background:rgba(39, 57, 92, 0.4);
  }
</style>
