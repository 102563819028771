<template>
  <div class="popup">
    <div>
      <p><span class="verticalLine"></span><span>综合能源服务</span></p>
      <p>分布式能源开发案例</p>
    </div>
    <div class="popup_conter">
      <li>
        <p><img src="../../assets/1.jpg" width="100%"></p>
        <div class="triangle_div">
      <li class="triangle_li">
        <P class="triangle"> <span class="el-icon-caret-right"></span><span>华能泗洪光伏领跑基地项目</span></P>
        <p class="triangle_p" >装机容量：100MW</p>
        <p>该项目位于泗洪县上塘镇天岗湖乡，是华能在江苏的首个国家级光伏领跑项目。项目建成投运，将进一步完善所在地区电网结构，为当地经济社会高质量发展提供清洁能源支撑。</p>
      </li>
      <li>
        <img src="../../assets/2.png" width="540">
      </li>
    </div>
    </li>

    <li>
      <p><img src="../../assets/3.png" width="100%"></p>
      <div class="triangle_div">
    <li class="triangle_li">
      <P class="triangle"> <span class="el-icon-caret-right"></span><span>华能太仓电厂灰场光伏</span></P>
      <p class="triangle_p" >装机容量：50MW</p>
      <p>该项目位于华能太仓电厂厂区内，利用灰场土地建成50兆瓦光伏电站，对综合利用灰场闲置土地发展新能源项目起到良好的示范作用。</p>
    </li>
    <li>
      <img src="../../assets/4.png" width="540">
    </li>
  </div>
  </li>

<!--  第三块-->
  <li>
  <p><img src="../../assets/5.png" width="100%"></p>
  <div class="triangle_div">
    <li>
      <img src="../../assets/4.png" width="540">
    </li>
    <li class="triangle_li" style="margin-left: 20px;margin-right: 0 !important;">
      <P class="triangle"> <span class="el-icon-caret-right"></span><span>华能灌云屋顶光伏</span></P>
      <p class="triangle_p" >装机容量：14MW</p>
      <p>该项目位于官运临港产业区中小型园，建于24座总面积约11.8万平方米生产厂房的屋顶上</p>
    </li>
  </div>
  </li>
  <!--  第四块-->
  <li>
    <p><img src="../../assets/7.png" width="100%"></p>
    <div class="triangle_div">
  <li>
    <img src="../../assets/8.png" width="540">
  </li>
  <li class="triangle_li" style="margin-left: 20px;margin-right: 0 !important;">
    <P class="triangle"> <span class="el-icon-caret-right"></span><span>华能淮阴电厂灰场光伏</span></P>
    <p class="triangle_p" >装机容量：30MW</p>
    <p>该项目位于华能淮阴电厂厂区内，项目建筑安装工程满负荷一次性并网网满成功，全区18个子系统方阵均运转正常。</p>
  </li>
  </div>
  </li>

  </div>
  </div>
</template>

<script>
export default {
  name: "Popup"
}
</script>

<style lang="less" scoped>
.left-first{
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* 定高 */
  height: 19rem;
  background: rgba(25, 38, 58, 0.8);
  margin-top: 20px;
  overflow: hidden;
}
/* 标题 */
.left-first>div:first-child{
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* border: 1px solid rgb(243, 245, 242); */
  /* 定高 */
  height: 2.125rem;
  color: #fff;
  font-size: .875rem;
  display: flex;
  line-height: 2.125rem;
  justify-content: space-between;
  padding:2rem 1.1rem 0rem ;
  background-color: rgba(25, 38, 58, 0.8);
}
.left-first>div:first-child>p:nth-child(1){
  color: #04EBF9;
  position: relative;
span:nth-of-type(2){
  margin-left: 10px;
}
}
.left-first>div:first-child>p:nth-child(2){
  color: #ACD8E1;
}
.left-first-left{
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  flex-basis: 46.5%;
  color: #3F6979;
  font-size: 12px;
  /*text-align: left;*/
}
.left-first-left div:nth-of-type(1){
  text-align: left;
  padding: 10px 0 0 20px;
p{
  line-height: 30px;
}
}
.left-first-left div:nth-of-type(2){
  text-align: left;
  padding: 10px 0 0 20px;
li{list-style: none}
li:nth-of-type(2){margin-top: 10px}
p{
  line-height: 26px;
}
}
.left-first-right{
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  flex-basis: 53.5%;
}
/* 内容内部 */
.left-first-left>div{
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /*定宽 */
  width: 13.5rem;
}

.left-first-left>div:first-child{
  background-image: url("../../static/image/left/月度1.png");
  background-repeat: no-repeat;
  /* 定高 */
  height: 6.5rem;
  margin-top: .5rem;
}
.left-first-left>div:nth-child(2){
  background-image: url("../../static/image/left/月度2.png");
  background-repeat: no-repeat;
  /* 定高 */
  height: 8.7rem;
}
.center{
  height: 100%;
}
.center ul{
  padding-top: 60px;
}
.center li{
  list-style: none;
  text-align: left;
  color: #ACD8E1;
  font-size: 16px;
  line-height: 36px;
  margin: 0 50px;
  cursor: pointer;
  display: flex;
}
.center li img{
  margin-right: 20px;
  vertical-align: middle;
  height: 36px;
}
.verticalLine{
  position: absolute;
  display: inline-block;
  border-right: 2px solid;
  height: 16px;
  top: 10px;
  color: #04EBF9;
}
/deep/ .el-dialog{
  border-radius: 10px;
}
/deep/ .el-dialog__header{
  display: none;
}
/deep/ .el-dialog__body{
  min-height: 300px;
  padding: 0 !important;
  color: #333;
}
.popup{
  position: relative;
}
.popup>div:nth-of-type(1){
  padding: 25px 40px 20px;
}
.popup p{
  line-height: 40px;
  font-size: 16px;
//font-weight: 600;
  font-family: '微软雅黑';
}
.popup .verticalLine{
  top: 36px;
  height: 20px;
  color: #2BA990;
}
.popup p span:nth-of-type(2){
  margin-left: 20px;
  color: #666;
  font-size: 18px;
}
.popup div:nth-of-type(1)>p:nth-of-type(2){
  color: #333;
  font-weight: 800;
  font-size: 16px;
  margin-top: 30px;
  line-height: 30px;
  padding: 0 6px;
  border-bottom: 1px solid #ccc;
}
.popup p:nth-of-type(3){
  padding: 0 38px
}
.popup_conter{
  height: 640px;
  overflow-y: auto;
  padding: 0 50px 20px;
}
.popup_conter li{
  list-style: none;
  margin: 20px 0;
}
.popup_conter img{
  margin: 0 auto;
  display: block;
}
/* 设置滚动条的样式 */
.popup_conter::-webkit-scrollbar {
  width: 6px;
}
/* 滚动槽 */
.popup_conter::-webkit-scrollbar-track {
  -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
  border-radius:10px;
}
/* 滚动条滑块 */
.popup_conter::-webkit-scrollbar-thumb {
  border-radius:10px;
  background:rgba(48, 46, 53, 0.5);
  -webkit-box-shadow:inset 006px rgba(0,0,0,0.5);
}
.popup_conter::-webkit-scrollbar-thumb:window-inactive {
  background:rgba(39, 57, 92, 0.4);
}
.triangle{
  color: #0865c7;
}
.triangle span:nth-of-type(2){
  font-weight: 600;
  font-size: 20px !important;
  color: #0865c7 !important;
}
.triangle_p {
  color: #0865c7;
  margin-top: -10px;
  padding-left: 34px;
}
.triangle_div{
  display: flex;
  margin-top: 30px;
}
.triangle_li{
  padding:60px 10px 0 60px;
  background: #f6f3f3;
  margin-right: 20px !important;
}
</style>
